<template>
    <div class="CEESAR-Dialog">
        <div class="CEESAR-DialogTitle">{{title}}</div>
        
        <div class="CEESAR-DialogContent">
            <slot />
        </div>

        <div class="CEESAR-DialogButtons">
            <CEESAR-Button v-for="button in buttons" v-bind:key="button.value"
                :label="button.label"
                :icon="button.icon"
                v-on:click="answer(button.value)"/>
            <CEESAR-Button label="Close" icon="close" v-if="defaultButton" v-on:click="onClose"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-Card",
    props:{
        title:{type: String, required: true},
        buttons: {type: Array, default: undefined}
    },
    data(){
        return{

        };
    },
    computed:{
        defaultButton(){
            if(this.buttons == undefined) return true;
            return false;
        }
    },
    methods:{
        answer(pAnswer){
            this.$ceesar.design.answerDialog(pAnswer);
            this.$ceesar.design.closeDialog();
        },
        onClose(){
            this.$ceesar.design.closeDialog();
        }
    }
}
</script>

<style>

</style>